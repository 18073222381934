import * as React from "react"
import {useStaticQuery, graphql} from 'gatsby'

const Banner = ({ isWhite }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          canRegister
          registerLink
          mailLink
        }
      }
    }
  `)
  const { canRegister, registerLink, mailLink } = site.siteMetadata;

  return (
      <div className={isWhite ? 'bg-white' : 'bg-gray-100'}>
        <div className='max-w-screen-lg mx-auto py-10 px-4 sm:px-6'>
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-5'>
            <div className='text-center sm:text-left'>
              <h1 className="text-2xl tracking-tight leading-8 sm:leading-none font-extrabold sm:text-3xl lg:text-4xl xl:text-5xl mb-4">
                {canRegister ? "Ready to sign up?" : "Ready for the next competition?"}
              </h1>
              <p className='text-md text-gray-500'>
                {canRegister ? 
                  "Fill out the registration form to save your spot at the International Biology Bowl!" : 
                  "Join our mailing list to get notified about IBB updates and future competitions!"}
              </p>
            </div>
            <div className='flex justify-center sm:justify-end items-center sm:text-right'>
              <a href={canRegister ? registerLink : mailLink} target="_blank" className="bg-blue-500 hover:bg-blue-400 focus:outline-none focus:bg-blue-400 px-5 py-3 border border-transparent text-base font-medium rounded-md text-white transition duration-150 ease-in-out">{canRegister ? "Register" : "Sign Up"}</a>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Banner
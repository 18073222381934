import React from 'react'
import BackgroundImage from 'gatsby-background-image'

const Hero = ({ image, title }) => {
  return (
    <BackgroundImage 
      className="bg-no-repeat bg-center bg-cover overflow-hidden non-index-bg text-center px-8 text-white"
      fluid={[
        "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))",
        image.childImageSharp.fluid,
    ]}>
      <div className="non-index-hero mt-40 mx-auto max-w-screen-xl px-4 sm:mt-32 sm:px-6 md:mt-36 lg:mt-36 xl:mt-44">
        <h1 className="text-5xl tracking-tight leading-10 font-extrabold text-gray-100 sm:text-6xl sm:leading-none md:text-7xl mb-8">{title}</h1>
      </div>
    </BackgroundImage>
  )
}

export default Hero
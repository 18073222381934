import * as React from "react"
import Layout from "../components/layout"
import SEO from '../components/SEO'
import Hero from '../components/hero'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Banner from "../components/banner"
import Results from '../data/results.json'

const CompetitionPage = () => {
  const { heroImage } = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "results.jpg" }) {
				childImageSharp {
					fluid(quality: 95) {
						...GatsbyImageSharpFluid
					}
				}
			}
    }
  `)

  return (
    <Layout>
      <SEO title='Results' description="View the results and winners of this year's International Biology Bowl here." />
      <Hero image={heroImage} title="Results" />
      {Results.map((result, index) => {
        return (
          <div className={(index % 2 == 0 ? 'bg-gray-100 text-gray-500' : 'bg-gray-800 text-gray-300') + (index==0 ? ' pt-10' : '')}>
            <div className='max-w-screen-xl mx-auto py-12 px-4 sm:px-6'>
              <h1 className={`px-5 text-2xl tracking-tight leading-10 sm:leading-none font-extrabold sm:text-3xl lg:text-4xl xl:text-4xl sm:mb-4 ` + (index%2==0 ? "text-black" : "text-white")}>
                  {result.name}
              </h1>
              <div className="flex flex-wrap overflow-hidden"> {/* -mx-3 sm:-mx-3 md:-mx-3 lg:-mx-3 xl:-mx-3 */}
                <div className="my-3 px-5 w-full md:px-5 md:w-1/2 lg:my-3 lg:px-5 lg:w-1/2 xl:my-3 xl:px-5 xl:w-1/2 px-auto">
                  <h1 className={`text-xl tracking-tight leading-10 sm:leading-none font-bold sm:text-2xl lg:text-3xl xl:text-3xl sm:mb-4 ` + (index%2==0 ? "text-black" : "text-white")}>
                    Records
                  </h1>
                  <p className='font-semibold text-xl mb-4'>{result.date}</p>
                  <div className='flex flex-col'>
                    <a href={result.bracket} target={result.bracket !== "#" ? "_blank" : ""} className={index%2==0 ? "text-center bg-blue-500 hover:bg-blue-400 focus:outline-none focus:bg-blue-400 px-5 py-3 border border-transparent text-base font-medium rounded-md text-white transition duration-150 ease-in-out" : "text-blue-700 text-center bg-blue-100 hover:bg-blue-200 focus:outline-none focus:bg-blue-200 px-5 py-3 border border-transparent text-base font-medium rounded-md transition duration-150 ease-in-out"}>Bowl Round Bracket</a>
                    <br/>
                    <a href={result.opendoc} target={result.opendoc !== "#" ? "_blank" : ""} className={index%2==0 ? "text-center bg-blue-500 hover:bg-blue-400 focus:outline-none focus:bg-blue-400 px-5 py-3 border border-transparent text-base font-medium rounded-md text-white transition duration-150 ease-in-out" : "text-blue-700 text-center bg-blue-100 hover:bg-blue-200 focus:outline-none focus:bg-blue-200 px-5 py-3 border border-transparent text-base font-medium rounded-md transition duration-150 ease-in-out"}>Open Round Results/Statistics</a>
                  </div>
                </div>
                <div className="my-3 mt-8 px-5 w-full md:my-3 md:px-5 md:w-1/2 lg:my-3 lg:mt-3 lg:px-5 lg:w-1/2 xl:my-3 xl:px-5 xl:w-1/2 block">
                  <h1 className={`text-xl tracking-tight leading-10 sm:leading-none font-bold sm:text-2xl lg:text-3xl xl:text-3xl sm:mb-4 ` + (index%2==0 ? "text-black" : "text-white")}>
                    Top 4
                  </h1>
                  <div>
                    {result.winners.map((x) => {
                      return (
                        <div className='mt-6'>
                          <p className='font-semibold text-xl mb-0.5'>{x.title}</p>
                          <p className='text-md'>{x.members}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
      {/* <div className='max-w-screen-xl mx-auto py-10 px-4 sm:px-6 pt-20 text-center'>
        <h1 className="text-xl tracking-tight leading-10 sm:leading-none font-extrabold text-gray-900 sm:text-2xl lg:text-3xl xl:text-4xl mb-4 sm:mb-6">
          2022 Bowl Round Results
        </h1>
        <GatsbyImage className="max-w-screen-lg rounded-md w-100 shadow-2xl" image={getImage(bracketImage.childImageSharp.gatsbyImageData)} alt="2022 Bowl Round Bracket" />
      </div>
      <div className='bg-gray-800'>
        <div className='max-w-screen-xl mx-auto py-10 px-4 sm:px-6'>
          <div className="flex flex-wrap overflow-hidden">
            <div className="my-3 px-3 w-full md:px-3 md:w-1/2 lg:my-3 lg:px-3 lg:w-1/2 xl:my-3 xl:px-3 xl:w-1/2 px-auto">
              <GatsbyImage className="rounded-lg block mx-auto lg:w-10/12" image={getImage(winnersImage.childImageSharp.gatsbyImageData)} alt="2022 Bowl Round Winners" />
            </div>
            <div className="my-3 mt-8 px-3 w-full md:my-3 md:px-3 md:w-1/2 lg:my-3 lg:mt-3 lg:px-3 lg:w-1/2 xl:my-3 xl:px-3 xl:w-1/2 block">
              <h1 className="text-2xl tracking-tight leading-10 sm:leading-none font-extrabold text-white sm:text-3xl lg:text-4xl xl:text-4xl sm:mb-4">
                2022 International Biology Bowl Winners
              </h1>
              <div className='text-gray-300'>
                {winners.map(({title, members}) => {
                  return (
                    <div className='mt-6'>
                      <p className='font-semibold text-xl mb-0.5'>{title}</p>
                      <p className='text-md'>{members}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
      <div className="max-w-screen-xl mx-auto py-10 px-2 sm:px-4 text-gray-900">
        <div className="flex flex-wrap overflow-hidden">
          <div style={{marginBottom: "24px"}} className="my-3 text-center px-3 w-full md:my-3 md:px-3 md:w-1/2 lg:my-3 lg:px-3 lg:w-1/2 xl:my-3 xl:px-3 xl:w-1/2">
            <h1 className="text-xl tracking-tight leading-10 sm:leading-none font-extrabold text-gray-900 sm:text-xl lg:text-2xl xl:text-3xl mb-4 sm:mb-8">
              2022 Open Round Results
            </h1>
            <p className='mb-4 text-gray-500'>Thank you to everyone who participated in the competition! We hope you had a great time working with your teammates. Also, congratulations to the top 32 teams, who competed for prizes in the Bowl Round!</p> <br />
            <a href="/files/top32_2022open.pdf" target="_blank" className="bg-blue-500 hover:bg-blue-400 focus:outline-none focus:bg-blue-400 px-5 py-3 border border-transparent text-base font-medium rounded-md text-white transition duration-150 ease-in-out">View the Top 32 Teams</a>
            <br />
          </div>
          <div className="mt-12 my-3 text-center px-3 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 lg:my-3 lg:px-3 lg:w-1/2 xl:my-3 xl:px-3 xl:w-1/2 block">
            <h1 className="text-xl tracking-tight leading-10 sm:leading-none font-extrabold text-gray-900 sm:text-xl lg:text-2xl xl:text-3xl mb-2 sm:mb-4">
              2022 Open Round Statistics
            </h1>
            <GatsbyImage className="md:my-auto w-100" image={getImage(histoImage.childImageSharp.gatsbyImageData)} alt="2022 Biology Bowl Open Round Histogram" />
          </div>
        </div>
      </div> */}
    {/* </div> */}
    <Banner isWhite={true} />
    </Layout>
  )
}

export default CompetitionPage